<template>
  <div class="has-hero-container">
    <div>
      <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>
      <div class="sp-hero sp-bg-light-blue">
        <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter(); options.page = 1">

          <AppFilterApplyButton
              v-if="filterStore.hasChanges"
              @click="applyFilter(); options.page = 1"
          ></AppFilterApplyButton>

          <v-slide-group show-arrows>
            <v-slide-item>
              <div class="filter-item">
                <app-filter-date-picker
                    :filterLabel="t('from')"
                    v-model="filterStore.filter.dateStart"
                    id="dateStart"
                    clearable
                ></app-filter-date-picker>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-item">
                <app-filter-date-picker
                    :filterLabel="t('to')"
                    v-model="filterStore.filter.dateEnd"
                    id="dateEnd"
                    clearable
                ></app-filter-date-picker>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-item">
                <v-text-field  v-model="filterStore.filter.client" filled :label="t('name')"/>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-item">
                <v-text-field v-model="filterStore.filter.address" filled :label="t('address')"/>
              </div>
            </v-slide-item>

            <v-slide-item class="filter-reason-filter">
              <servapp-status-filter v-model=" filterStore.filter.state"/>
            </v-slide-item>
          </v-slide-group>
        </v-container>
      </div>
    </div>
    <v-container fluid>

      <v-row class="data-table-toolbar">
        <v-spacer></v-spacer>
        <v-btn
            elevation="0"
            color="primary"
            class="icon-left"
            :to="{ path: '/servappt/appointments/create'}"
        >
          <v-icon>mdi-plus</v-icon>
          {{ t('create') }}
        </v-btn>
      </v-row>

      <v-row v-if="dataLoaded && totalItems === 0">
        <div class="empty-datatable">
          <v-icon>mdi-alert-circle-outline</v-icon>
          {{ t('no-data') }}
        </div>
      </v-row>
      <v-row v-if="dataLoaded && totalItems > 0">
        <v-data-table
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="totalItems"
            :loading="loading"
            class="elevation-0"
            :sort-by="initialSortBy"
            :sort-desc="initialSortDesc"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-options': [25, 50, 100, 200],
              pageText: '{0}-{1} von {2}',
              'items-per-page-text': t('table.items-per-page')
            }"
        >
          <v-progress-linear v-show="loading" slot="progress" color="primary" indeterminate></v-progress-linear>

          <template v-slot:[`item.begin`]="{ item }">
            <span class="ellipsis">{{ getFormattedDate(item.begin) }}</span>
          </template>
          <template v-slot:[`item.time`]="{ item }">
            <span class="ellipsis">{{ getFormattedTimeSlot(item.begin, item.end) }}</span>
          </template>
          <template v-slot:[`item.address`]="{ item }">
            <span class="ellipsis">{{ item.address }}</span>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <span class="ellipsis">{{ item.name }}</span>
          </template>

          <template v-slot:[`item.zip`]="{ item }">
            <span class="ellipsis">{{ item.zip }}, {{ item.city }}</span>
          </template>
       
          <template v-slot:[`item.state`]="{ item }">
            <span class="ellipsis"><b>{{  t("states." + item.state ) }}</b></span>
          </template>

          <template v-slot:[`item.edit`]="{ item }">
            <router-link :to="{ path: '/servappt/appointments/' + item.id }" class="edit-row-table">
              <v-icon
                  small
                  class="edit-row-button"
              >
                edit
              </v-icon>
            </router-link>
            
          </template>
        </v-data-table>
      </v-row>

    </v-container>

  </div>
</template>

<script>
import { HTTP } from './../auth'
import toolbar from '../components/layouts/Navigation.vue'
import moment from 'moment/moment'
import { useServapptFilterStore } from '@/stores/ServappAppointmentFilterStore'
import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
import AppFilterDatePicker from '@/components/vuetify/AppFilterDatePicker'
import ServappStatusFilter from '@/components/filter-components/ServapptAppointmentStateFilter.vue'
import ServapptAreaFilter from  '@/components/filter-components/ServapptAreaFilter'

export default {
  name: 'ServapptAppointmentsIndex',
  components: {
    ServappStatusFilter,
    AppFilterDatePicker,
    AppFilterApplyButton,
    toolbar,
    ServapptAreaFilter
  },
  setup() {
    const filterStore = useServapptFilterStore()
    return {filterStore}
  },
  data() {
    return {
      items: null,
      totalItems: 0,
      dataLoaded: false,
      error: false,
      loading: false,
      options: {
        itemsPerPage: 25
      },
      initialSortBy: 'name',
      initialSortDesc: false,
    }
  },
  created() {
    this.getItems()
  },
  watch: {
    options: {
      handler() {
        this.getItems()
      },
      deep: true,
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.t('table.day'),
          align: 'start',
          sortable: true,
          value: 'begin'
        },
        {
          text: this.t('table.time'),
          align: 'start',
          sortable: false,
          value: 'time'
        },
        {
          text: this.t('table.name'),
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: this.t('table.zip'),
          align: 'start',
          sortable: true,
          value: 'zip'
        },
        {
          text: this.t('table.address'),
          align: 'start',
          sortable: true,
          value: 'address'
        },
        {
          text: this.t('table.phone'),
          align: 'start',
          sortable: true,
          value: 'phone'
        },
        {
          text: this.t('table.email'),
          align: 'start',
          sortable: true,
          value: 'email'
        },
        {
          text: this.t('table.status'),
          align: 'start',
          sortable: true,
          value: 'state'
        },
        {
          text: this.t('table.edit'),
          value: 'edit',
          sortable: false,
          align: 'center'
        }
      ]
    }
  },
  methods: {
    t: function (key) {
      return this.$t('servappt.appointments.list.' + key)
    },
    buildFilter() {
      let filter = this.filterStore.filter
      return {
        from: filter.dateStart,
        to: filter.dateEnd,
        userid: filter.selectedUser?.id,
        status: filter.status,
      }
    },
    reload() {
      this.items = []
      this.getItems()
    },
    applyFilter() {
      this.filterStore.filterApplied()
      this.reload();
    },
    getItems() {
      this.loading = true
      this.apiCall().then(data => {
        this.items = data.items
        this.totalItems = data.total
        this.loading = false
        this.dataLoaded = true
      });
    },
    prepareUrl(sortBy, sortDesc, page, itemsPerPage) {
      let queryStringParts = []
      if (this.filterStore.filter.dateStart) {
        queryStringParts.push('dateStart=' + this.filterStore.filter.dateStart)
      }
      if (this.filterStore.filter.dateEnd) {
        queryStringParts.push('dateEnd=' + this.filterStore.filter.dateEnd)
      }

      if (this.filterStore.filter.state.length > 0) {
        queryStringParts.push('st=' + this.filterStore.filter.state)
      }

      if (this.filterStore.filter.address.length > 0) {
        queryStringParts.push('address=' + this.filterStore.filter.address)
      }

      if (this.filterStore.filter.client.length > 0) {
        queryStringParts.push('client=' + this.filterStore.filter.client)
      }

      if (sortBy) {
        queryStringParts.push('sortBy[]=' + (sortBy[0] ?? ''));

        //for ZIP add extra sort
        if (sortBy[0] == "zip") {
          queryStringParts.push('sortBy[]=city');
        }

        let direction = sortDesc[0];
        queryStringParts.push('sortDesc[]=' + direction)
        //for ZIP add extra sort
        if (sortBy[0] == "zip") {
          queryStringParts.push('sortDesc[]=' + direction)
        }
      }
      page = page ? page : 1
      queryStringParts.push('page=' + page)
      queryStringParts.push('itemsPerPage=' + itemsPerPage)
      return '?' + queryStringParts.join('&')
    },
    apiCall() {
      return new Promise((resolve, reject) => {
        const {sortBy, sortDesc, page, itemsPerPage} = this.options

        let url = this.prepareUrl(sortBy, sortDesc, page, itemsPerPage)

        HTTP.get('servappt/appointments' + url).then(function (response) {
          let items = response.data.data
          let total = response.data.total

          resolve({
            items,
            total,
          })
        }.bind(this))
      })
    },
    getFormattedDate(val) {
      return moment(val).format('DD.MM.YYYY')
    },
    getFormattedTimeSlot(begin, end) {
      if (begin == end) {
        return moment(begin).format('HH:mm')
      } else {
        return moment(begin).format('HH:mm') + "–" + moment(end).format('HH:mm')
      }
    },
    formatSelectedDate() {
      return moment(this.data.date).format('DD.MM.YYYY') + " " + this.formatTime(this.data)
    },
  }
}
</script>
