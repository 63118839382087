<template>
  <div>

    <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

    <v-container fluid class="settings">

      <loading :error="error" :dataLoaded="dataLoaded"></loading>

      <v-form ref="form">

        <div v-if="dataLoaded">

          <v-row class="settings-wrapper">

            <v-card elevation="0">
              <v-card-title>{{ t('email.group-title') }}</v-card-title>
              <v-card-text>
                <v-switch
                  v-model="data.ideabox_email_enabled.value"
                  inset
                  :label="data.ideabox_email_enabled.value ? t('email.toggle.yes'): t('email.toggle.no')"
                ></v-switch>

                <br>

                <v-text-field
                  :label="t('email.email-address')"
                  v-model="data.ideabox_email.value"
                  filled
                  :rules="[
                    v => (!data.ideabox_email_enabled.value || (data.ideabox_email_enabled.value && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v))) || $t('validation.valid.email')
                  ]"
                  hide-details="auto"
                ></v-text-field>
              </v-card-text>
            </v-card>

          </v-row>

        </div>

      </v-form>

    </v-container>

    <v-snackbar
        ref="snackbar"
        v-model="snackbar"
        timeout="4000"
    >
      {{ message }}
    </v-snackbar>

    <transition name="fade">
      <div class="bottom-navigation modal-nav">
        <v-container fluid>
          <v-row>
            <v-btn
              color="primary"
              text
              class="icon-left"
              @click="clear()"
            >
              <v-icon>mdi-close</v-icon>
              {{ t('reset') }}
            </v-btn>
            <v-spacer></v-spacer>
            <app-dialog-confirm-save
              :validateForm="$refs.form"
              @confirmed="event => update(event)"
            ></app-dialog-confirm-save>
          </v-row>
        </v-container>
      </div>
    </transition>

  </div>

</template>


<script>
import { HTTP } from '@/auth'
import toolbar from '@/components/layouts/Navigation'
import loading from '@/components/layouts/Loading'
import AppDialogConfirmSave from '@/components/vuetify/AppDialogConfirmSave'

export default {
  name: 'SettingsIdeaBox',
  components: {
    toolbar,
    loading,
    AppDialogConfirmSave
  },
  data() {
    return {
      data: [],
      settings: {
        ideabox_email: {},
        ideabox_email_enabled: {},
      },
      dataLoaded: false,
      validated: false,
      message: 'Erfolgreich gespeichert.',
      messageSuccess: 'Erfolgreich gespeichert.',
      error: null,
      errorMessage: 'Die Daten konnten nicht gespeichert werden, prüfen Sie Ihre Verbindung und versuchen Sie es nochmal.',
      snackbar: false
    }
  },
  created() {
    this.getData()
  },
  methods: {
    t: function (key) {
      return this.$t('ideabox.settings.' + key);
    },
    reload() {
      this.$nextTick(function () {
        this.getData()
      }.bind(this))
    },
    update (isConfirmed) {
      if(!isConfirmed){
        this.clear()
        return
      }
      for (var item in this.data) {
        HTTP.patch('settings/' + this.data[item].id, this.data[item]).then(function (response) {
          this.message = this.messageSuccess
          this.openSnackbar()
        }.bind(this)).catch(function (error) {
          if (error.response) {
            this.message = this.errorMessage
          }
        }.bind(this))
      }
    },
    clear() {
      this.dataLoaded = false
      this.data = []
      this.getData()
    },
    getData() {
      HTTP.get('settings?key=ideabox').then(function (response) {
        this.settings.ideabox_email = response.data.filter(item => item.key === 'ideabox_email').shift()
        this.settings.ideabox_email_enabled = response.data.filter(item => item.key === 'ideabox_email_enabled').shift()

        this.data = Object.assign({}, this.settings)
        this.data.ideabox_email_enabled.value = this.data.ideabox_email_enabled.value === 'true'

        this.dataLoaded = true
        this.error = false
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
        }
      }.bind(this))
    },
    openSnackbar() {
      setTimeout(() => {
        this.snackbar = true
      }, 600)
    }
  }
}
</script>

<style scoped lang="scss">
.apply-setting {
  color: #FFF !important;
  background: #FF5252 !important;
}

.settings-wrapper {
  justify-content: space-between;

  .v-card {
    width: calc(50% - 16px);
    margin-bottom: 32px;
  }
}
</style>
