import { render, staticRenderFns } from "./SettingsIdeaBox.vue?vue&type=template&id=039e4638&scoped=true"
import script from "./SettingsIdeaBox.vue?vue&type=script&lang=js"
export * from "./SettingsIdeaBox.vue?vue&type=script&lang=js"
import style0 from "./SettingsIdeaBox.vue?vue&type=style&index=0&id=039e4638&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "039e4638",
  null
  
)

export default component.exports