<template>
    <div class="has-hero-container">
        <div>
            <toolbar :dataLoaded="true">{{ $t('chat-groups.list.title') }}</toolbar>

            <div class="sp-hero sp-bg-light-blue">
                <v-container fluid class="hero-filter"></v-container>
            </div>

            <v-container fluid>
                <v-row class="data-table-toolbar">
                    <v-spacer></v-spacer>
                    <v-btn
                        elevation="0"
                        color="primary"
                        class="icon-left"
                        :to="{ path: '/chat-groups/create'}"
                    >
                        <v-icon>mdi-plus</v-icon>
                        {{ $t('chat-groups.list.create') }}
                    </v-btn>
                </v-row>

                <v-row v-if="dataLoaded && items.length === 0">
                    <div class="empty-datatable">
                        <v-icon>mdi-alert-circle-outline</v-icon>
                        {{ t('no-data') }}
                    </div>
                </v-row>

                <v-row v-else>
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :loading="loading"
                        class="elevation-0"
                        :sort-by="initialSortBy"
                        :sort-desc="initialSortDesc"
                        :footer-props="{
                            showFirstLastPage: true,
                            firstIcon: 'mdi-arrow-collapse-left',
                            lastIcon: 'mdi-arrow-collapse-right',
                            prevIcon: 'mdi-minus',
                            nextIcon: 'mdi-plus',
                            'items-per-page-options': [10, 25, 50],
                            pageText: '{0}-{1} von {2}',
                            'items-per-page-text': $t('components.data-table.items-per-page')
                        }"
                    >
                        <template v-slot:[`item.picture`]="{ item }">
                            <img height="50" width="50" :src="item.picture_url"/>
                        </template>

                        <template v-slot:[`item.name`]="{ item }">
                            {{ item.name }}
                        </template>

                        <template v-slot:[`item.members`]="{ item }">
                            <span>{{ item.member_count }} </span>
                        </template>

                        <template v-slot:[`item.message`]="{ item }">
                            <router-link :to="{ path: '/chat-messages/' + item.id }" class="edit-row-table">
                                <v-icon small class="edit-row-button">messages</v-icon>
                            </router-link>
                        </template>

                        <template v-slot:[`item.details`]="{ item }">
                            <router-link :to="{ path: '/chat-groups/' + item.id }" class="edit-row-table">
                                <v-icon small class="edit-row-button">edit</v-icon>
                            </router-link>
                        </template>
                    </v-data-table>
                </v-row>
            </v-container>
        </div>

    </div>
</template>

<script>
import { HTTP } from '@/auth'
import toolbar from '@/components/layouts/Navigation'

export default {
    name: 'ChatGroupsIndex',
    components: {
        toolbar
    },
    data() {
        return {
            items: [],
            loading: false,
            options: {
                itemsPerPage: 10
            },
            initialSortBy: '',
            initialSortDesc: false,
            dataLoaded: false,
        }
    },
    created() {
        this.loadData()
    },
    computed: {
        headers() {
            return [
                {
                    text: this.$t('chat-groups.list.table.picture'),
                    align: 'center',
                    sortable: false,
                    value: 'picture',
                    width: '75px',
                },
                {
                    text: this.$t('chat-groups.list.table.name'),
                    align: 'start',
                    sortable: false,
                    value: 'name'
                },
                {
                    text: this.$t('chat-groups.list.table.members'),
                    align: 'center',
                    sortable: false,
                    value: 'members',
                    width: '100px',
                },
                {
                    text: this.$t('chat-groups.list.table.message'),
                    align: 'center',
                    sortable: false,
                    value: 'message',
                    width: '100px',
                },
                {
                    text: this.$t('chat-groups.list.table.details'),
                    align: 'center',
                    sortable: false,
                    value: 'details',
                    width: '100px',
                },
            ];
        },
    },
    methods: {
        async loadData() {
            this.loading = true;
            try {
                let response = await HTTP.get('/chat/groups')
                this.items = response.data;
            } catch (e) {
                this.$root.infoNotification.showMessage('Daten konnten nicht geladen werden.' + e.message)
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>
